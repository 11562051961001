import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LazyRoutingService {
  constructor(private router: Router) {}

  public ensureModuleIsLoadedForDeviceId(routerPath: string, refType: string): void {
    const existingConfigEntry = this.router.config.filter((c) => c.path === routerPath);

    if (!existingConfigEntry) {
      const moduleName = `Type${refType}Module`;
      const moduleImport = `../../asset-types/type-${refType}/type-${refType}.module`;
      import(moduleImport).then((m) => m[moduleName]);
    }
  }
}
